.modal__body ul {
  padding: 0;
}

.modal__body li {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
  padding: 10px;
  color: #5335ca;
  border-radius: 10px;
  margin-bottom: 5px;
  transition: color 0.3s, background-color 0.3s;
}

.modal__body li > *:first-child {
  margin-right: 10px;
}

.modal__body li:focus,
.modal__body li:hover {
  outline: none;
  cursor: pointer;
  color: #fff;
  background-color: rgba(83, 53, 202, 0.5);
}

.cosmetics {
  overflow: hidden;
  position: relative;
}

.cosmetics::before,
.cosmetics::after {
  position: absolute;
  content: "";
  display: block;
  width: 55vw;
  height: 55vw;
  border-radius: 50%;
  background-color: #fff;
  background: #673ab7;
  background: linear-gradient(to right, #41287a, #8c4df8);
}

.cosmetics::before {
  top: 0;
  left: 0;
  transform: translate(-60%, -60%);
}

.cosmetics::after {
  bottom: 0;
  right: 0;
  transform: translate(60%, 60%);
}
