.headerLogo {
    width: 150px !important;
    height: auto !important;
}

.headerProfileArea .MuiButtonBase-root {
    padding: 0 !important;
}

@media only screen and (max-width: 768px) {
    .headerLogo {
        width: 125px !important;
    }

    .headerProfileArea .MuiButtonBase-root p {
        display: none;
    }
}