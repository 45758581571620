/* @media only screen and (max-width: 900px) {
    .fullWidthInput {
        width: 100% !important;
    }

    .fullWidthInput > *{
        width: 100% !important;
    }
} */

.editHoursModalSignUp__table {
    width: 100%;
    min-width: 600px;
    /* overflow: auto; */
  }
  @media (max-width: 800px) {
    .editHoursModalSignUp__table {
      max-width: 800px;
      min-width: 300px;
    }
  }
  @media (max-width: 380px) {
    .editHoursModalSignUp__table {
      /* min-width: 100vw; */
      min-width: 250px;
    }
  }

  @media (max-width: 325px) {
    .editHoursModalSignUp__table {
      /* min-width: 100vw; */
      min-width: 200px;
    }
  }

.font-weight{
    font-weight: 600;
}


.fontFixe{
    font-weight: 600;
}

@media (max-width: 600px) {
    .fontFixe {
      font-size: 11px;
    }
  }
  @media (max-width: 420px) {
    .fontFixe {
      font-size: 10px;
    }
    /* .fontFixe:not(:last-of-type) {
      margin-bottom: 5px;
    } */
  }
  
  @media (max-width: 340px) {
    .fontFixe {
      font-size: 9px;
    }
  }